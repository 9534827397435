import 'react-native-dotenv';
import Constants from 'expo-constants';
import { get } from 'lodash';

import manifest from '../../app.json';

const getConfig = () => {
  const version =
    Constants.manifest?.version || get(manifest, 'expo.version') || '0.0.0';
  const slug = process.env.REACT_APP_SLUG || '';
  const config = {
    env: process.env.REACT_ENV || 'undefined',
    debug: process.env.REACT_DEBUG === 'True',
    appVersion: version,
    filesKeyPrefix: 'files-',
    formsKeyPrefix: 'form-',
    addAssessmentsFilesKey: 'add',
    storeKey: process.env.REACT_STORE_KEY! || `${slug}-store`,
    tokenKey: process.env.REACT_TOKEN_KEY! || `${slug}-token`,
    onboardingKey: process.env.REACT_ONBOARDING_KEY || `${slug}-onboarding`,
    microsoftAuthClientId: process.env.MICROSOFT_AUTH_CLIENT_ID || '',
    microsoftAuthTenantId: process.env.MICROSOFT_AUTH_TENANT_ID || '',
    microsoftAuthRedirectUrl: process.env.MICROSOFT_AUTH_REDIRECT_URL || '',
    catUrl: process.env.CAT_URL || '',
    ratUrl: process.env.RAT_URL || '',
    pbiUrl: process.env.PBI_URL || '',
    amdUrl: process.env.AMD_URL || '',
    manualUrl: process.env.MANUAL_URL || '',
    urls: {
      api: process.env.REACT_API_URL!,
      signUp: 'auth/registration/',
      signIn: 'auth/token/',
      signInWithMicrosoft: 'auth/microsoft/login/',
      logOut: 'auth/logout/',
      refreshToken: 'auth/token/refresh/',
      users: 'users/',
      actions: 'actions/',
      catActions: 'actions/cat-actions/',
      refreshCatActions: 'actions/cat-actions/refresh/',
      assessments: 'assessments/',
      userDrafts: 'assessments/user-drafts/',
      answers: 'assessments/answers/',
      statistics: 'assessments/statistics/',
      actionItems: 'assessments/answers/action-items/',
      updateAnswers: 'assessments/answers/bulk-update/',
      attachments: 'assessments/attachments/',
      deleteAttachments: 'assessments/attachments/bulk-delete/',
      assessmentForms: 'assessment-forms/',
      formSections: 'assessment-forms/form-sections/',
      questions: 'assessment-forms/questions/',
      performanceUnits: 'performance-units/',
      siteTypes: 'site-types/',
      sites: 'sites/',
      categories: 'categories/',
      selfVerificationTypes: 'self-verification-types/',
      exportPdf: 'assessments/export_pdf/',
      assessmentSchedules: 'drumbeat-schedule/assessments/',
      assessmentSchedulesSite: 'drumbeat-schedule/assessments/schedule/',
    },
    bpEwellsUrl: process.env.BP_EWELLS_URL || '',
  };

  console.log({ config });

  return config;
};

export default getConfig();
